<template>
    <div class="field-wrapper" :class="showError() ? 'field-wrapper--error' : ''">
        <label :for="inputId" class="field-label" :class="isFocused || localValue != '' ? 'field-label--up' : ''">
            {{ fieldLabel }}<span v-if="required" class="text-red-default">&nbsp;*</span>
        </label>
        <div class="absolute z-50 bg-gray-5 overflow-hidden h-full w-full rounded-small" v-if="loading">
            <div class="absolute w-1/3 h-[150%] -top-1/4 bg-gray-6 animate-form-loading"></div>
        </div>
        <input
            :type="fieldType"
            name=""
            ref="input"
            class="field-input"
            :class="{
                'mb-2': showError() || $slots.helper,
                'opacity-0': loading
            }"
            :id="inputId"
            :value="localValue"
            :required="required"
            :disabled="loading || disabled"
            @blur="onBlur"
            @focus="onFocus"
            @input="updateInput($event.target.value)"
        />
        <div class="text-gray-2 font-bold text-xs" v-if="!showError() && $slots.helper">
            <slot name="helper"></slot>
        </div>
        <div class="text-red-default text-xs" v-if="showError() && $slots.error">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isFocused: false,
            localValue: '',
            dirty: false,
        };
    },
    mounted() {
    },
    props: {
        fieldLabel: String,
        fieldType: String,
        required: Boolean,
        valid: Boolean,
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onBlur() {
            this.isFocused = false;

            if (this.localValue.length > 0) {
                this.dirty = true;
            }
        },
        onFocus() {
            this.isFocused = true;
        },
        updateInput(value) {
            this.localValue = value;
            this.$emit('update:modelValue', this.localValue);
        },
        showError() {
            if (this.localValue.length <= 0 && !this.dirty) {
                return false;
            }
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || '';
    },
    computed: {
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        }
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
